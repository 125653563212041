<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.account" @change="Search" placeholder="账号"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addAdminer">新增管理员</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="用户登录账号">
        <template v-slot="scope">
          <span>{{ scope.row.account }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="用户登录密码">
        <template v-slot="scope">
          <span>{{ scope.row.password }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="用户名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户状态">
        <template v-slot="scope">
          <span>{{ scope.row.status===1?'正常': '禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户创建时间">
        <template v-slot="scope">
          <span>{{ timeToDate(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户修改时间">
        <template v-slot="scope">
          <span>{{ timeToDate(scope.row.update_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editUser(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteadmin(scope.row.id)">删除</el-button>
          <router-link style="margin-left:10px" :to="'/admin/adminer/power/'+ scope.row.id">
            <el-button type="info" size="small">权限分配</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'管理员信息'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="用户登录账号" prop="account" label-width="110px">
          <el-input v-model="form.account" autocomplete="off" :disabled="edit"></el-input>
        </el-form-item>
        <el-form-item label="用户登录密码" prop="password" label-width="110px">
          <el-input v-model.trim="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="name" label-width="110px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone" label-width="110px">
          <el-input v-model.trim="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户状态" prop="status" label-width="110px">
          <el-select v-model="form.status" clearable placeholder="请选择">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="禁用" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="权限分配" v-model="powerShow" width="600px">
      <el-form>
        <el-form-item label="分配角色:" label-width="100px">
          <el-checkbox-group v-model="checkList">
            <el-checkbox @change="checkedRole($event,item)" v-for="item in Rolelist" :value="item.role_id" :key="item.role_id" :label="item.name"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="分配区域:" label-width="100px">
          <el-checkbox-group v-model="regioncheck">
            <el-checkbox @change="checkRegin($event,item)" v-for="item in Regionlist" :value="item.value" :key="item.value" :label="item.name"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="powerShow = false">完成</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import md5 from 'js-md5'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入账号'))
      }
      let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      if (!reg.test(value)) {
        callback(new Error('账号为邮箱'))
      } else {
        callback()
      }
    }
    var checkPassword = (rule, value, callback) => {
      let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{6,24}$/
      if (!reg.test(value)) {
        callback(new Error('密码须由数字、小写字母、大写字母、特殊字符至少三种构成,长度至少为6位!'))
      } else {
        callback()
      }
    }
    return {
      Regionlist: [
        { value: 'eu', name: '欧/亚/非'},
        { value: 'cn', name: '中国'},
        { value: 'us', name: '美洲'},
      ],
      RegionNameVal: {
        eu: '欧/亚/非',
        cn: '中国',
        us: '美洲',
      },
      title: '',
      search: {
        page: 1,
        limit: 10,
        kname: '',
        account: ''
      },
      total: 0,
      list: [],
      dialogFormVisible: false,
      form: {
        id: 0,
        account: '',
        password: '',
        name: '',
        status: 1,
        phone: '',
      },
      rules: {
        account: [{ required: true, validator: checkAccount, trigger: 'blur' }],
        password: [{ required: true, validator: checkPassword, trigger: 'blur' }],
        name: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        status: [{ required: true, message: '请输入用户状态', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }]
      },
      edit: false,
      checkList: [],
      Rolelist: [],
      powerShow: false,
      adminId: '',
      roleNameId: {},
      checkrole: [],
      checkRegionList: [],
      regioncheck: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    GetCheckRole(item){
      this.checkList = []
      this.api.AdminRoleList({
        adminer_id: item
      }).then(res => {
        if (res.data.code == 200) {
          this.GetCheckRegion(item)
          res.data.data.forEach(key => {
            this.checkList.push(this.roleNameId[key.role_id])
          })
          this.checkrole = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetCheckRegion(item){
      this.regioncheck =[]
      this.api.AdminRegionList({
        adminer_id: item
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.forEach(key => {
            this.regioncheck.push(this.RegionNameVal[key.region])
          })
          this.checkRegionList = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetroleList (item) {
      this.roleNameId = {}
      this.api.RoleList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.GetCheckRole(item)
          this.Rolelist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.roleNameId[item.id]= item.name
          });
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search () {
      this.api.Userlist({
        page: this.search.page,
        limit: this.search.limit,
        kname: this.search.kname,
        account: this.search.account
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addAdminer () {
      // 清空表单验证
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editUser(item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.UserDetail({id:item.id}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    deleteadmin(item) {
      this.$confirm('是否删除该管理员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.UserDelete({id: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    addconfirm () {
      this.update()
    },
    update(){
      this.api.UserUpdate({
        id: +this.form.id,
        account: this.form.account,
        password: this.form.password=='*****'?'':md5(this.form.password),
        name: this.form.name,
        status: +this.form.status,
        phone: this.form.phone
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    editconfirm () {
      this.update()
    },
    powerdistribut(item) {
      this.GetroleList(item)
      this.adminId = item
      this.powerShow = true
    },
    checkRegin(event,item) {
      if (event) {
        this.distributeRegion(item)
      } else {
        this.deleteDistriRegion(item)
      }
    },
    distributeRegion (item) {
      this.api.AdminRegionUpdate({
        adminer_id: this.adminId,
        region: item.value
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          return
        }
        this.$message.error('分配失败')
      })
    },
    deleteDistriRegion (item) {
      let paramId = ''
      this.checkRegionList.forEach(ele => {
        if (item.value == ele.region) {
          paramId = ele.id
          return
        }
      })
      this.api.AdminRegionDelete({
        id: paramId
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('取消分配成功')
          return
        }
        this.$message.error('取消分配失败')
      })
    },
    checkedRole (event,check){
      if (event) {
        this.distribute(check)
      } else {
        this.deleteDistri(check)
      }
    },
    // 取消分配
    deleteDistri(ele) {
      let paramId = ''
      this.checkrole.forEach(item => {
        if (item.role_id == ele.id) {
          paramId = item.id
          return
        }
      })
      this.api.AdminRoleDelete({
        id: paramId
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('取消分配成功')
          return
        }
        this.$message.error('取消分配失败')
      })
    },
    // 分配权限
    distribute(item) {
      this.api.AdminRoleUpdate({
        adminer_id: this.adminId,
        role_id: item.id
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('分配成功')
          return
        }
        this.$message.error('分配失败')
      })
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
